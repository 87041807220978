import bindAll from 'lodash.bindall';
import React from 'react';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';
import ReactModal from 'react-modal';
import { openDeviceLibrary } from '../../reducers/modals';

import Box from '../box/box.jsx';
import styles from './telemetry-modal.css';
import axios from 'axios';
import config from '../../containers/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import SBFileUploaderHOC from '../../lib/sb-file-uploader-hoc.jsx';
import { connect } from 'react-redux';
import {
    requestNewProject
} from '../../reducers/project-state';
const messages = defineMessages({
    label: {
        id: 'gui.telemetryOptIn.label',
        defaultMessage: 'Report statistics to improve OpenBlock',
        description: 'OpenBlock telemetry modal label - for accessibility'
    },
    bodyText1: {
        defaultMessage: 'The OpenBlock Team is always looking to better understand how OpenBlock is used around the ' +
            'world. To help support this effort, you can allow OpenBlock to automatically send usage information to ' +
            'the OpenBlock Team.',
        description: 'First paragraph of body text for telemetry opt-in modal',
        id: 'gui.telemetryOptIn.body1'
    },
    bodyText2: {
        defaultMessage: 'The information we collect includes language selection, blocks usage, and some events like ' +
            'saving, loading, and uploading a project. We DO NOT collect any personal information. Please see our ' +
            '{privacyPolicyLink} for more information.',
        description: 'First paragraph of body text for telemetry opt-in modal',
        id: 'gui.telemetryOptIn.body2'
    },
    privacyPolicyLink: {
        defaultMessage: 'Privacy Policy',
        description: 'Link to the OpenBlock privacy policy',
        id: 'gui.telemetryOptIn.privacyPolicyLink'
    },
    optInText: {
        defaultMessage: 'Share my usage data with the Openblock Team',
        description: 'Text for telemetry modal opt-in button',
        id: 'gui.telemetryOptIn.optInText'
    },
    optInTooltip: {
        defaultMessage: 'Enable telemetry',
        description: 'Tooltip for telemetry modal opt-in button',
        id: 'gui.telemetryOptIn.optInTooltip'
    },
    optOutText: {
        defaultMessage: 'Do not share my usage data with the Openblock Team',
        description: 'Text for telemetry modal opt-in button',
        id: 'gui.telemetryOptIn.optOutText'
    },
    optOutTooltip: {
        defaultMessage: 'Disable telemetry',
        description: 'Tooltip for telemetry modal opt-out button',
        id: 'gui.telemetryOptIn.optOutTooltip'
    },
    settingWasUpdated: {
        defaultMessage: 'Your setting was updated.',
        description: 'Message indicating that the telemetry setting was updated and saved',
        id: 'gui.telemetryOptIn.settingWasUpdated'
    },
    closeButton: {
        defaultMessage: 'Close',
        description: 'Text for the button which closes the telemetry modal dialog',
        id: 'gui.telemetryOptIn.buttonClose'
    }
});

// This should be at least as long as the CSS transition
const SETTING_WAS_UPDATED_DURATION_MS = 3000;

class FileModal extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            'handleCancel',
            'handleOptInOutChanged',
            'handleFileClick'
        ]);
        this.state = {
            // if the settingWasUpdated message is displayed, this will be the ID of its removal timer
            settingWasUpdatedTimer: null,
            files: []
        };
    }



    onStartSelectingFileUpload = (cloud, name) => {
        // Chama a função passada pelas props
        this.props.onStartSelectingFileUploadChangeFileCloud(cloud, name);
    };

    componentWillUnmount() {
        if (this.state.settingWasUpdatedTimer) {
            clearTimeout(this.state.settingWasUpdatedTimer);
        }
    }
    handleCancel() {
        this.props.onRequestClose();
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }
    handleOptInOutChanged(e) {
        if (e.target.value === 'true') {
            if (this.props.onOptIn) {
                this.props.onOptIn();
                this.handleSettingWasUpdated();
            }
        } else if (e.target.value === 'false') {
            if (this.props.onOptOut) {
                this.props.onOptOut();
                this.handleSettingWasUpdated();
            }
        }
    }
    handleSettingWasUpdated() {
        if (this.state.settingWasUpdatedTimer) {
            clearTimeout(this.state.settingWasUpdatedTimer);
        }
        const newTimer = setTimeout(
            () => this.handleSettingWasUpdatedTimeout(newTimer),
            SETTING_WAS_UPDATED_DURATION_MS
        );
        this.setState({
            settingWasUpdatedTimer: newTimer
        });
    }
    handleSettingWasUpdatedTimeout(thisTimer) {
        if (thisTimer !== this.state.settingWasUpdatedTimer) {
            // some other timer has taken over
            return;
        }
        this.setState({
            settingWasUpdatedTimer: null
        });
    }


    componentDidMount() {
        // Carregue os arquivos quand
        // o o componente for montado
        this.loadFiles();

    }

     // step 1: this is where the upload process begins
     handleStartSelectingFileUpload() {
        this.createFileObjects(); // go to step 2
    }
    cloud = false;
    handleStartSelectingFileUploadCloud() {
        this.props.onOpenFileModal();
        //this.createFileObjects(cloud, name); // go to step 2
    }
    // step 1: this is where the upload process begins
    handleStartSelectingFileUploadChangeFileCloud(cloud, name) {
        this.cloud = true;
        this.createFileObjects(cloud, name); // go to step 2
    }
    // step 2: create a FileReader and an <input> element, and issue a
    // pseudo-click to it. That will open the file chooser dialog.
    createFileObjects(cloud = null, name = null) {
        // redo step 7, in case it got skipped last time and its objects are
        // still in memory
        this.removeFileObjects();
        // create fileReader
        this.fileReader = new FileReader();
        this.fileReader.onload = this.onload;

        //Se buscar no computador ele não cria os elementos na tela
        if (cloud == null) {
            // create <input> element and add it to DOM
            this.inputElement = document.createElement('input');
            this.inputElement.accept = '.ob,.sb,.sb2,.sb3';
            this.inputElement.style = 'display: none;';
            this.inputElement.type = 'file';
            this.inputElement.onchange = this.handleChange; // connects to step 3
            document.body.appendChild(this.inputElement);
            // simulate a click to open file chooser dialog
            this.inputElement.click();
        } else {
            this.handleChange(null, cloud, name);
        }

    }

    handleChange(e, file = null, name = null) {
        const {
            intl,
            isShowingWithoutId,
            loadingState,
            projectChanged,
            userOwnsProject
        } = this.props;

        const thisFileInput = e ? e.target : null;
        if (thisFileInput && thisFileInput.files) {
            this.fileToUpload = thisFileInput.files[0];
        } else if (file) {
            this.fileToUpload = name;
        }

        if (this.fileToUpload) {
            console.log("teste");
            // Verifica se o upload é permitido
            let uploadAllowed = true;
            if (userOwnsProject || (projectChanged && isShowingWithoutId)) {
                /*
                uploadAllowed = this.props.onShowMessageBox(MessageBoxType.confirm,
                    intl.formatMessage(sharedMessages.replaceProjectWarning));*/
            }
            if (uploadAllowed && file !== null) {
                this.fileToUpload = file;
                this.handleFinishedLoadingUpload();
            }
            else if (uploadAllowed) {
                this.props.requestProjectUpload(loadingState);
            } else {
                this.removeFileObjects();
            }

        }
        this.handleCancel();
    }

    // step 4 is below, in mapDispatchToProps

    // step 5: called from componentDidUpdate when project state shows
    // that project data has finished "uploading" into the browser
    handleFinishedLoadingUpload() {
        if (this.fileToUpload && this.fileReader) {
            // begin to read data from the file. When finished,
            // cues step 6 using the reader's onload callback
            this.fileReader.readAsArrayBuffer(this.fileToUpload);
        } else {
            this.props.cancelFileUpload(this.props.loadingState);
            // skip ahead to step 7
            this.removeFileObjects();
        }
    }
    // used in step 6 below
    getProjectTitleFromFilename(fileInputFilename) {
        if (!fileInputFilename) return '';
        // only parse title with valid scratch project extensions
        // (.ob .sb, .sb2, and .sb3)
        const matches = fileInputFilename.match(/^(.*)\.((ob)|(sb[23]))?$/);
        if (!matches) return '';
        return matches[1].substring(0, 100); // truncate project title to max 100 chars
    }
    // step 6: attached as a handler on our FileReader object; called when
    // file upload raw data is available in the reader
    onload() {
        if (this.fileReader) {
            this.props.onLoadingStarted();
            const filename = this.fileToUpload && this.fileToUpload.name;
            let loadingSuccess = false;
            this.props.vm.loadProject(this.fileReader.result)
                .then(() => {

                    if (filename) {
                        const uploadedProjectTitle = this.getProjectTitleFromFilename(filename);
                        this.props.onSetProjectTitle(uploadedProjectTitle);
                    }
                    loadingSuccess = true;
                    if (this.cloud) {
                        this.props.onCloseFileModal();
                    }

                })
                .catch(error => {
                    log.warn(error);
                    this.props.onShowMessageBox(MessageBoxType.alert,
                        `${this.props.intl.formatMessage(messages.loadError)}\n${error}`);
                })
                .then(() => {

                    this.props.onLoadingFinished(this.props.loadingState, loadingSuccess);
                    // go back to step 7: whether project loading succeeded
                    // or failed, reset file objects
                    this.removeFileObjects();
                });
        }
    }
    // step 7: remove the <input> element from the DOM and clear reader and
    // fileToUpload reference, so those objects can be garbage collected
    removeFileObjects() {
        if (this.inputElement) {
            this.inputElement.value = null;
            document.body.removeChild(this.inputElement);
        }
        this.inputElement = null;
        this.fileReader = null;
        this.fileToUpload = null;
    }

    loadFiles() {
        try {

            if (config.file != null && config.file.length == 110) {

                this.state.hasRequested = true;

                    // Configura um timeout de 5 segundos antes de iniciar a requisição

                    const body = {
                        folder: config.userId,
                        filename: config.userId
                    };
                    axios.post(`${config.apiUrl}/read-file`, body, {
                        responseType: 'arraybuffer'
                    })
                        .then(response => {
                            const blob = this.arrayBufferToBlob(response.data, 'application/octet-stream');
                            this.handleStartSelectingFileUploadChangeFileCloud(blob, config.userId);
                        });

            } else {

                const response = axios.get(config.apiUrl + '/files?folder=' + config.userId);
                response.then(x => {
                    this.setState({ files: x.data });
                    if (config.file != null) {
                        this.handleFileClick(config.file);
                        config.file = null;
                    }
                });
            }

        } catch (error) {
            console.error('Falha ao carregar arquivos:', error);
        }

    }

    arrayBufferToBlob = (arrayBuffer, mimeType) => {
        // Cria um Blob a partir do ArrayBuffer e do tipo MIME
        return new Blob([arrayBuffer], { type: mimeType });
    };

    handleFileClick(file) {
       // alert("Para abrir esse projeto você deve selecionar o dispositivo primeiro");
        const body = {
            folder: config.userId,
            filename: file
        };
        config.file = file;
        axios.post(`${config.apiUrl}/read-file`, body, {
            responseType: 'arraybuffer'
        })
            .then(response => {
                const blob = this.arrayBufferToBlob(response.data, 'application/octet-stream'); // Ajuste o tipo MIME conforme necessário
                this.onStartSelectingFileUpload(blob, file);
            })
            .catch(error => {
                console.error('Falha ao ler o arquivo:', error);
            });
            this.props.onClickNew(false);
            this.props.onOpenDeviceLibrary();

           // config.file = null;
          //  this.handleCancel


    }

    handleDeleteClick(fileId) {
        const body = {
            folder: config.userId,
            filename: fileId
        };
        if (window.confirm('Tem certeza que deseja excluir este arquivo?')) {
            try {
                // Solicita exclusão do arquivo via POST
                axios.post(`${config.apiUrl}/delete-file`, body).then(x => {
                    // Atualiza a lista de arquivos
                    this.setState(prevState => ({
                        files: prevState.files.filter(file => file.id !== fileId),
                        fileIdToDelete: null
                    }));
                    this.loadFiles();
                });

            } catch (error) {
                console.error('Falha ao excluir o arquivo:', error);
            }
        }
    }


    render() {
        const isUndecided = (typeof this.props.isTelemetryEnabled !== 'boolean');
        const isOff = (this.props.isTelemetryEnabled === false);
        const isOn = (this.props.isTelemetryEnabled === true);
        const settingWasUpdated = this.state.settingWasUpdatedTimer && (
            <FormattedMessage {...messages.settingWasUpdated} />
        );
        return (<ReactModal
            isOpen
            className={styles.modalContent}
            contentLabel={this.props.intl.formatMessage(messages.label)}
            overlayClassName={styles.modalOverlay}
            onRequestClose={this.handleCancel}
        >
            <div dir={this.props.isRtl ? 'rtl' : 'ltr'} >
                <Box className={styles.illustration} />
                <Box className={styles.body}>
                    <p>Selecione o projeto que deseja abrir</p>
                    <ul className={styles.fileList}>
                        {this.state.files.map(file => (
                            <li
                                key={file}
                                className={styles.fileItem}
                                onClick={() => this.handleFileClick(file)}
                            >
                                <FontAwesomeIcon
                                    icon={faTrashAlt}
                                    className={styles.fileItemIcon}
                                    onClick={(e) => {
                                        e.stopPropagation(); // Evita que o clique no ícone de lixeira acione o clique do item
                                        this.handleDeleteClick(file);
                                    }}
                                />
                                {file}
                            </li>
                        ))}
                    </ul>
                    <Box className={styles.buttonRow}>
                        <span
                            className={styles.settingWasUpdated}
                            key={this.state.settingWasUpdatedTimer} // restart CSS fade when timer changes
                        >{settingWasUpdated}</span>
                        <button
                            className={styles.optIn}
                            onClick={this.props.onRequestClose}
                        >
                            Novo Projeto
                        </button>
                    </Box>
                </Box>
            </div>
        </ReactModal>);
    }

}
/*
const mapDispatchToProps = dispatch => ({
    onFileChange: (event) => dispatch(handleChange(event))
});

export default connect(null, mapDispatchToProps)(injectIntl(FileModal));*/

const mapDispatchToProps = (dispatch, ownProps) => ({
    onOpenDeviceLibrary: () => dispatch(openDeviceLibrary()),
    onClickNew: needSave => dispatch(requestNewProject(needSave)),
    onOpenFileModal: () => dispatch(openFileModal()),
    onCancel: () => {
        if (config.file != null) {
            dispatch(openFileModal());
        }
        dispatch(closeConnectionModal());
    },
    onConnected: peripheralName => {
        dispatch(setConnectionModalPeripheralName(peripheralName));
    },
    onClickListAll: state => {
        dispatch(setListAll(state));
    }
});
export default SBFileUploaderHOC(connect(null, mapDispatchToProps)(injectIntl(FileModal)));
//export default SBFileUploaderHOC(injectIntl(FileModal));
